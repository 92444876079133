import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

import { Container as _Container } from '@components/_global';
import { P } from '@components/Typography';
import Layout from '@components/Layout';
import Logo from '@components/Logo';

const Container = styled(_Container)`
  min-height: 100vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const NotFoundPage = () => (
  <Layout>
    <Container>
      <div>
        <Logo />

        <div css="margin-top: 0.8em">
          <P size="medium">
            No, no, no nothing's here, <Link to="/">let's go home</Link>
          </P>
        </div>
      </div>
    </Container>
  </Layout>
);

export default NotFoundPage;
